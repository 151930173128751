"use strict";

const HELPRut = require('../helpers/rut')

// Class definition
var KTModalUserSearch = function () {
    // Private variables
    var element;
    var errorElement;
    var resultsElement;
    var wrapperElement;
    var emptyElement;
    var searchObject;
    var searchTable;
    var recentTable;
    var startWrapperElBlockUI;

    // Private functions
    var process = function (search) {

        if (!KTUtil.hasClass(errorElement, 'd-none')) {
            KTUtil.addClass(errorElement, 'd-none');
        }

        if (!KTUtil.hasClass(emptyElement, 'd-none')) {
            KTUtil.addClass(emptyElement, 'd-none');
        }

        let searchValue = search.getQuery();
        
        if (searchValue == '') {
            search.complete();
            return;
        }

        // if(HELPRut.isRut(searchValue) === false) {
        //     search.complete();
        //     KTUtil.setHTML(errorElement.querySelector('div.alert'), 'RUT no válido!');
        //     KTUtil.removeClass(errorElement, 'd-none');
        //     return;
        // }
        
        requestData(searchValue)

    };

    var clear = function (search) {
        // Show recently viewed
        if (!KTUtil.hasClass(errorElement, 'd-none')) {
            KTUtil.addClass(errorElement, 'd-none');
        }

        if (!KTUtil.hasClass(resultsElement, 'd-none')) {
            KTUtil.addClass(resultsElement, 'd-none');
        }

        if (!KTUtil.hasClass(emptyElement, 'd-none')) {
            KTUtil.addClass(emptyElement, 'd-none');
        }

    };


    var requestData = (rut) => {

        let params = {
            'rut': rut
        }

        axios
            .post("/consulta", params)
            .then(function (response) {

                if (
                    Sugar.Object.isObject(response.data) === true
                    && Sugar.Object.has(response.data, "success") === true
                    && response.data.success === true
                ) {

                    let data = response.data.data;
                    let info = `<h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center m-10">
                        ${data.name}
                    </h1>`;

                    KTUtil.each(data.matricula, function(row) {
                        console.log(row);

                        let posgrat = '';
                        if (row.situacion_matricula == 'NO MATRICULADO' && row.saldo_derecho_matricula == 0 && row.saldo_arancel == 0) {
                            posgrat = `<div class="alert alert-primary d-flex align-items-center p-5 mb-10">Posible Gratuidad</div>`;
                        }

                        info += `<div class="card shadow-sm mb-5 mb-xl-10">
                            <!--begin::Card header-->
                            <div class="card-header cursor-pointer">
                                <!--begin::Card title-->
                                <div class="card-title m-0">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bold text-dark">${row.carrera}</span>
                                    <span class="text-gray-400 pt-2 fw-semibold fs-6">${row.facultad}</span>
                                </h3>
                                </div>
                                <!--end::Card title-->

                            </div>
                            <!--begin::Card header-->

                            <!--begin::Card body-->
                            <div class="card-body p-9">

                                ${posgrat}

                                <!--begin::Row-->
                                <div class="row mb-7">

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Num Matricula</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.matricula}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Situacion Matricula</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="badge py-1 px-1 fs-7 badge-light-primary">${row.situacion_matricula}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Via Ingreso</label>
                                    <!--end::Label-->

                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.via_ingreso}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Fecha Ingreso</label>
                                    <!--end::Label-->

                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.fecha_ingreso}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Tipo Alumno</label>
                                    <!--end::Label-->

                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="badge py-1 px-1 fs-7 badge-light-primary">${row.tipo_alumno}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Fecha Activ Matricula</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.fecha_activ_matric}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Ingreso al Portal</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="badge py-1 px-1 fs-7 badge-light-primary">${row.ingreso_a_portal}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Actualizo datos pers</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.actualizo_datos_personales}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Estado Mandato</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.estado_mandato}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Valida Cuotas</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.valida_cuotas}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Saldo Derecho Matrícula</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.saldo_derecho_matricula}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Saldo Arancel</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.saldo_arancel}</span>
                                    </div>
                                    <!--end::Col-->

                                    <!--begin::Label-->
                                    <label class="col-lg-4 fw-semibold text-muted">Saldo Deuda</label>
                                    <!--end::Label-->
                                    <!--begin::Col-->
                                    <div class="col-lg-8">
                                        <span class="fw-bold fs-6 text-gray-800">${row.saldo_deuda}</span>
                                    </div>
                                    <!--end::Col-->

                                  
                                </div>
                                <!--end::Row-->

                            </div>
                            <!--end::Card body-->
                        </div>`;

                    });

                    KTUtil.setHTML(resultsElement, info);
                    KTUtil.removeClass(resultsElement, 'd-none');
                    searchObject.complete()

                } else {
                    return Promise.reject(response)
                }
            })
            .catch(function (error) {
                // handle error
                let data = {};
                let status = 400;

                if (
                    Sugar.Object.isObject(error.response) === true
                    && Sugar.Object.has(error.response, "data") === true
                ) {
                    data = error.response.data;
                    status = error.response.status;
                }
                else if (
                    Sugar.Object.isObject(error) === true
                    && Sugar.Object.has(error, "data") === true
                ) {
                    data = error.data;
                }

                let message = 'Error al procesar su requerimiento, intente más tarde. Si el problema persiste, avise al administrador.';
                if (status == 400) {
                    message = "Error, token CSRF faltante o incorrecto. Actualice la página.";
                }

                if (
                    Sugar.Object.isObject(data) === true
                    && Sugar.Object.has(data, "message") === true
                ) {
                    message = data.message;
                }

                console.error(error);
                if (error.response.status == 404) {
                    KTUtil.setHTML(emptyElement.querySelector('div.alert'), message);
                    KTUtil.removeClass(emptyElement, 'd-none');
                } else {
                    KTUtil.setHTML(errorElement.querySelector('div.alert'), message);
                    KTUtil.removeClass(errorElement, 'd-none');
                }

                searchObject.complete()

            })
            .then(function () {
            });




    };


    // Public methods
    return {
        init: function () {
            // Elements`
            element = document.querySelector('#start_alumnos_search_handler');

            if (!element) {
                return;
            }

            // initTable();

            wrapperElement = element.querySelector('[data-kt-search-element="wrapper"]');
            errorElement = element.querySelector('[data-kt-search-element="error"]');
            resultsElement = element.querySelector('[data-kt-search-element="result"]');
            emptyElement = element.querySelector('[data-kt-search-element="empty"]');

            // Initialize search handler
            searchObject = new KTSearch(element);
            // console.log(searchObject.getInputElement());

            // Search handler
            searchObject.on('kt.search.process', process);

            // Clear handler
            searchObject.on('kt.search.clear', clear);

            // startWrapperElBlockUI = new KTBlockUI(document.getElementById('start_wrapper'));
            // startWrapperElBlockUI.block();

            // // busquedas recientes
            // recentSearchInit();

        }
    };
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
    KTModalUserSearch.init();
});