"use strict";

/**
 * @class HELPRut  base utilize class that privides helper functions in alerts messages
 */
 
 // Class definition
 var HELPRut = function () {
     return {
         
        /**
         * Valida que un rut ingresado sea valido
         * @param string|number rut 
         * @returns boolean
         */
         isRut: function (rut) {
            rut = String(rut);
            const rutLimpio = rut.replace(/[^0-9kK-]/g, "");
            if (rutLimpio.length < 8) return false;

            const num = parseInt(rutLimpio.slice(0, -1), 10);
            const dgv = rutLimpio.slice(-1).toUpperCase();

            const dvCalc = HELPRut.calculateDV(num);
            return dvCalc === dgv;
        },

        /**
         * calcula el digito verificador de un rut
         * @param number rut 
         */
        calculateDV: function (rut) {
            const cuerpo = `${rut}`;
            // Calcular Dígito Verificador
            let suma = 0;
            let multiplo = 2;

            // Para cada dígito del Cuerpo
            for (let i = 1; i <= cuerpo.length; i++) {
                // Obtener su Producto con el Múltiplo Correspondiente
                const index = multiplo * cuerpo.charAt(cuerpo.length - i);

                // Sumar al Contador General
                suma += index;

                // Consolidar Múltiplo dentro del rango [2,7]
                if (multiplo < 7) {
                    multiplo += 1;
                } else {
                    multiplo = 2;
                }
            }

            // Calcular Dígito Verificador en base al Módulo 11
            const dvEsperado = 11 - (suma % 11);
            if (dvEsperado === 10) return "K";
            if (dvEsperado === 11) return "0";
            return `${dvEsperado}`;
        },
         
     }
 }();
 
 // Webpack support
 if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
     module.exports = HELPRut;
 }